import Swiper from "swiper/js/swiper";

$(document).ready(function() {
    bannerHomeSlider();
});

function bannerHomeSlider() {
    new Swiper (".swiper-banner-home", { //instance need to be unique (ex: some-slider)
        loop: false,
        autoplay: {
            delay: 5000,
        },
        slidesPerGroup: 1,
        slidesPerView: 1,
        preloadImages: false,
        lazy: {
            loadOnTransitionStart: true
        },
        watchOverflow: true,
        breakpoints: {
            1200: {
                simulateTouch: false
            },
            992: {
                simulateTouch: false
            },
            768: {
                simulateTouch: true
            }
        },
        navigation: {
            prevEl: ".swiper-button-prev",  //prev must be unique (ex: some-slider-prev)
            nextEl: ".swiper-button-next", //next must be unique (ex: some-slider-next)
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
    });
}