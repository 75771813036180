import {get} from "./ajax";
import {removeOverlay, renderOverlay} from "./overlay";
import Splide from "@splidejs/splide";

let ajaxProductFinder = null;

export function showLabel()
{
    if (document.querySelector('#product-finder-label') && document.querySelector('#product-finder-label').classList.contains('pf-label-hidden')) {
        setTimeout(function () {
            document.querySelector('#product-finder-label').classList.remove('pf-label-hidden');
        }, 900);
    }
}

export function answer()
{
    let version = document.querySelector('#pf-v').value;
    let answers = getAnswers();
    if (answers) {
        if ((answers.match(/,/g)||[]).length < 2) {
            let loading = document.querySelector('#pf-loading');
            loading.classList.remove('hidden');
            loading.classList.add('d-flex');
        } else {
            let working = document.querySelector('#pf-working');
            working.classList.remove('hidden');
            working.classList.add('d-flex');
        }
    }
    if (ajaxProductFinder) {
        ajaxProductFinder.abort();
    }
    let body = document.querySelector('#pf-body');

    ajaxProductFinder = get(
        '/ajax/website/product-finder/answer',
        {
            answers: answers,
            version: version,
        },
        function(data) {
            body.innerHTML += data.view;
            setTimeout(showAnswers, 100);
            setTimeout(updateElementsVisibility, 150);
            if (!data.showResults) {
                setTimeout(function () {
                    let loading = document.querySelector('#pf-loading');
                    loading.classList.add('hidden');
                    loading.classList.remove('d-flex');
                }, 150);
            } else {
                initProductAdvantagesCarousels();
                let loading = document.querySelector('#pf-loading');
                loading.classList.add('hidden');
                loading.classList.remove('d-flex');
                setTimeout(function () {
                    let working = document.querySelector('#pf-working');
                    working.classList.add('hidden');
                    working.classList.remove('d-flex');
                }, 3000);
            }
            ajaxProductFinder = null;
        },
        function() {
            ajaxProductFinder = null;
        }
    );
}

function ShowItems(items, delay, mobile, iteration)
{
    if (iteration < 3) {
        $(items[0]).fadeIn(300)
            .delay(delay)
            .fadeOut(300)
            .promise()
            .done(function () {
                items.splice(0, 1);
                if (items.length > 0) {
                    ShowItems(items, delay, mobile, ++iteration);
                }
                if (items.length === 0) {
                    items = $(".specialName-item").hide();
                    ShowItems(items, delay, mobile, ++iteration);
                }
            });
    } else {
        $(".specialName-item").hide();
        $(".specialName-item").first().show();
    }
}

function initProductAdvantagesCarousels()
{
    if (document.querySelectorAll('.productAdvantages').length) {
        var items = Array.prototype.slice.call(document.querySelectorAll(".specialName-item")); //.hide();
        ShowItems(items, 4000, '', 0);
    }

    let elements = document.querySelectorAll('.pfpp-advantages');
    elements.forEach(function(element) {
        new Splide(element, {
            type: 'fade',
            rewind: true,
            arrows: false,
            pagination: false,
            drag: false,
            autoplay: true,
            speed: 1000
        }).mount();
    });
}

function getAnswers()
{
    return document.querySelector('#pf-answers').value;
}

function showAnswers()
{
    let currentAnswers = document.querySelector('.pf-answers.current');
    let nextAnswers = document.querySelector('.pf-answers.next');
    let results = document.querySelector('.pf-results.next');

    if (currentAnswers) {
        currentAnswers.classList.add('prev');
        currentAnswers.classList.remove('current');
    }
    if (nextAnswers) {
        nextAnswers.classList.remove('next');
        nextAnswers.classList.add('current');
    }
    if (results) {
        results.classList.remove('next');
        results.classList.add('current');
    }
}

export function openProductFinder()
{
    removeOverlay(document.body);
    renderOverlay(document.body, 'append', ['pf-overlay']);
    let productFinder = document.querySelector('#product-finder');
    productFinder.classList.add('pf-open');
}

export function closeProductFinder()
{
    let productFinder = document.querySelector('#product-finder');
    productFinder.classList.remove('pf-open');
    removeOverlay(document.body);
    restartProductFinder();
}

export function goBackProductFinder()
{
    let currentQuestion = document.querySelector('.pf-answers.current');
    let currentResults = document.querySelector('.pf-results.current');
    let lastQuestionNumber = 0;
    if (currentQuestion) {
        lastQuestionNumber = parseInt(currentQuestion.dataset.number) - 1;
    } else if(currentResults) {
        lastQuestionNumber = parseInt(currentResults.dataset.last);
    }
    let previousQuestion = document.querySelector(`.pf-answers.prev[data-number="${lastQuestionNumber}"]`);
    let answers = document.querySelector('#pf-answers').value.split(',');
    let answersNames = document.querySelector('#pf-answers-names').value.split(',');
    answers.pop();
    answersNames.pop();
    document.querySelector('#pf-answers').value = answers.join(',');
    document.querySelector('#pf-answers-names').value = answersNames.join(',');

    if (currentQuestion) {
        currentQuestion.classList.add('next');
        currentQuestion.classList.remove('current');
        setTimeout(function() {
            currentQuestion.parentNode.removeChild(currentQuestion);
        }, 200);
    } else if (currentResults) {
        currentResults.classList.add('next');
        currentResults.classList.remove('current');
        setTimeout(function() {
            currentResults.parentNode.removeChild(currentResults);
        }, 200);
    }
    if (previousQuestion) {
        previousQuestion.classList.remove('prev');
        previousQuestion.classList.add('current');
    }
    updateElementsVisibility();
}

export function restartProductFinder()
{
    document.querySelector('#pf-answers').value = '';
    document.querySelector('#pf-answers-names').value = '';
    document.querySelectorAll('.pf-answers').forEach(function(element) {
        element.parentNode.removeChild(element);
    });
    let results = document.querySelector('.pf-results');
    if (results) {
        results.classList.add('next');
        results.classList.remove('current');
        setTimeout(function() {
            results.parentNode.removeChild(results);
        }, 200);
    }
    updateElementsVisibility();
}

function updateElementsVisibility()
{
    let backButton = document.querySelector('#pf-back');
    let restartButton = document.querySelector('#pf-restart');
    let currentQuestion = document.querySelector('.pf-answers.current');
    let currentResults = document.querySelector('.pf-results.current');
    let body = document.querySelector('#pf-body');
    let startContent = document.querySelector('#pf-start-content');
    let title = document.querySelector('#pf-title');
    let footer = document.querySelector('#pf-footer');

    backButton.classList.add('hidden');
    restartButton.classList.add('hidden');
    body.classList.add('bg-white');
    startContent.classList.add('hidden');
    title.classList.add('hidden');
    footer.classList.add('hidden');

    if (!currentQuestion && !currentResults) {
        body.classList.remove('bg-white');
        startContent.classList.remove('hidden');
    }
    if (currentQuestion) {
        title.classList.remove('hidden');
        footer.classList.remove('hidden');
        if (currentQuestion.dataset.number != 1) {
            backButton.classList.remove('hidden');
        }
    }
    if (currentResults) {
        title.classList.remove('hidden');
        footer.classList.remove('hidden');
        backButton.classList.remove('hidden');
        restartButton.classList.remove('hidden');
    }
}

export function getCurrentProductFinderPage()
{
    let page = 'Start Page';

    let currentQuestion = document.querySelector('.pf-answers.current');
    let currentResults = document.querySelector('.pf-results.current');
    if (currentQuestion) {
        page = 'Question '+currentQuestion.dataset.number;
    } else if (currentResults) {
        page = 'Result Page';
    }

    return page;
}