export function renderOverlay(element, action, extraClasses = [])
{
    if (document.querySelector('.overlay')) return;
    let overlay = getOverlayDiv(extraClasses);
    if (action === 'append') element.append(overlay);
    if (action === 'prepend') element.prepend(overlay);
    document.body.classList.add('overflow-hidden');
}

export function removeOverlay(element)
{
    let overlay = element.querySelector('.overlay');
    if (overlay) overlay.remove();
    document.body.classList.remove('overflow-hidden');
}

function getOverlayDiv(classes)
{
    let div = document.createElement("div");
    div.classList.add('overlay', 'z-index-low');

    if (classes !== []) {
        classes.forEach(function(className) {
            div.classList.add(className);
        });
    }

    return div;
}