import {ecPromoClick, trackEvent, trackLinkEvent} from "../../library/analytics";
import {openShoppingCartIfNeeded} from "./product-shopping-cart";
//import {checkWishListStickers} from "../../library/wishlist";
import Swiper from "swiper/js/swiper";
import {onVisible} from "../../website/scripts/Tools/visible-element-observer";
//import {itemDatalayerPush} from "../../website/webpack/modules/analytics";
// import {loadAlgoliaAutoComplete} from "../../website/webpack/modules/algolia-autocomplete";

$(document).ready(function() {
    // loadAlgoliaAutoComplete('#autocomplete');

    onVisible(document.querySelectorAll(".fire-promotion-datalayer"), function (element) {
        let id = element.querySelector('input[name="promoId"]').value;
        let name = element.querySelector('input[name="promoName"]').value;
        let creativeName = element.querySelector('input[name="promoCreative"]').value;
        let position = element.querySelector('input[name="promoPosition"]').value;
        sendPromotionEvents('view_promotion', id, name, creativeName, position);
    });

    onVisible(document.querySelectorAll(".fire-main-collection-datalayer"), function (element) {
        let id = element.dataset.promoId || null;
        let name = element.dataset.promoName || null;
        let creativeName = element.dataset.promoCreativeName || null;
        let position = element.dataset.promoCreativeSlot || null;
        sendPromotionEvents('view_promotion', id, name, creativeName, position);
    });

    window.addEventListener('beforeunload',(event) => {
        if (promotionsViews.length) {
            dataLayer.push({ecommerce: null});
            dataLayer.push({
                event: 'view_promotion',
                ecommerce: {
                    items: promotionsViews
                }
            });
            promotionsViews = [];
        }
        return false;
    });

    $('#hero-container').on('mousedown', ".hero-content", function(event) {
        if (event.which == 3) {return;}
        if ($(this).hasClass('no-clickable')) {return;}
        let heroImage = $(this);
        let link = heroImage.find('.hero-text a');
        let id = heroImage.find('input[name="promoId"]').val();
        let name = heroImage.find('input[name="promoName"]').val();
        let creative = heroImage.find('input[name="promoCreative"]').val();
        let position = heroImage.find('input[name="promoPosition"]').val();
        ecPromoClick(id, name, creative, position);
        // GA4 event
        sendPromotionEvents('select_promotion', id, name, creative, position);
        trackLinkEvent('Home', 'HeroImage', name, link, event);
    });
    $(".containerCollection").mousedown(function(event) {
        if (event.which == 3) {return;}
        let link = $(this).find('.nameStickersCollection a');
        // GA4 event
        let id = $(this).data('promoId');
        let name = $(this).data('promoName');
        let creative = $(this).data('promoCreativeName');
        let position = $(this).data('promoCreativeSlot');
        sendPromotionEvents('select_promotion', id, name, creative, position);
        trackLinkEvent('Home', 'MainCollections', link.attr('title'), link, event);
    });
    $(".ts-item").mousedown(function(event) {
        if (event.which == 3) {return;}
        let link = $(this).find('.ts-item-title a');
        // GA4 event
        let id = $(this).data('promoId');
        let name = $(this).data('promoName');
        let creative = $(this).data('promoCreativeName');
        let position = $(this).data('promoCreativeSlot');
        sendPromotionEvents('select_promotion', id, name, creative, position);
        trackLinkEvent('Home', 'MainCollections', link.attr('title'), link, event);
    });
    $(".collectionLink").mousedown(function(event){
        if (event.which == 3) {return;}
        event.preventDefault();
        // GA4 event
        let id = $(this).data('promoId');
        let name = $(this).data('promoName');
        let creative = $(this).data('promoCreativeName');
        let position = $(this).data('promoCreativeSlot');
        sendPromotionEvents('select_promotion', id, name, creative, position);
        let link = $(this);
        trackLinkEvent('Home', 'MainCollections', link.attr('title'), link, event);
    });
    $("#home-allstickers-btn").mousedown(function(event){
        if (event.which == 3) {return;}
        event.preventDefault();
        let link = $(this);
        trackLinkEvent('Home', 'AllStickers', '', link, event);
    });
    $(".service-box").mousedown(function(event) {
        if (event.which == 3) {return;}
        let link = $(this).find('a');
        trackLinkEvent('Home', 'Services', link.attr('title'), link, event);
    });
    $(".contentServices").mousedown(function(event) {
        if (event.which == 3) {return;}
        let link = $(this).find('.servicesTitle a');
        trackLinkEvent('Home', 'Services', link.attr('title'), link, event);
    });
    $("#home-tenquality-btn").mousedown(function(event){
        if (event.which == 3) {return;}
        event.preventDefault();
        let link = $(this);
        trackLinkEvent('Home', 'TenQuality', '', link, event);
    });
    $('.containerVideoYoutube').click(function(){
        trackEvent('Home', 'Banner', 'video-' + $(this).find('.bannerTitle').text());
    });
    $(".containerPromo").mousedown(function(event) {
        if (event.which == 3) {return;}
        let banner = $(this);
        let link = banner.find('.bannerTitle a');
        let id = banner.find('input[name="promoId"]').val();
        let name = banner.find('input[name="promoName"]').val();
        let creative = banner.find('input[name="promoCreative"]').val();
        let position = banner.find('input[name="promoPosition"]').val();
        ecPromoClick(id, name, creative, position);
        // GA4 event
        sendPromotionEvents('select_promotion', id, name, creative, position);
        trackLinkEvent('Home', 'Banner', name, link, event);
    });
    $(".home-about-tag").mousedown(function(event){
        if (event.which == 3) {return;}
        event.preventDefault();
        let link = $(this);
        trackLinkEvent('Home', 'AboutUs', 'tag-' + link.attr('title'), link, event);
    });
    $(".home-about-country").mousedown(function(event){
        if (event.which == 3) {return;}
        event.preventDefault();
        let link = $(this);
        trackLinkEvent('Home', 'AboutUs', 'country-' + link.attr('title'), link, event);
    });
    openShoppingCartIfNeeded();
    //checkWishListStickers();
    $('img.lazy-load-img').unveil();

    var swiperAdv = new Swiper('.swiper-adv', {
        slidesPerView: 'auto',
        direction: 'horizontal',
        watchOverflow: true,
        spaceBetween: 0,
        freeMode: true,
        grabCursor: true,
        autoplay: {
            delay: 1500,
        },
        mousewheel: true,
        navigation: {
            nextEl: '.swiper-adv-button-next',
            prevEl: '.swiper-adv-button-prev',
        },
        on: {
            setTranslate: function (translate) {
                if (translate !== 0 ) {
                    let desp = (translate * 100) / (($('.textBox.lastTextBox').width() * swiperAdv.slides.length) - $('.swiper-adv').width());
                    let desp2 = ((desp * ($('#advantages-image').width() - $('#advImageContainer').width())) / 100);
                    $('#advantages-image').css("transform", "translate3d(" + desp2 + "px, 0px, 0px)");
                } else {
                    $('#advantages-image').css("transform", "translate3d(0px, 0px, 0px)");
                }
            }
        }
    });
    $('#home-ar-banner').click(function() {
        trackEvent('Realidad Aumentada', 'Home', 'Open banner');
    });
});

var promotionsViews = [];
function sendPromotionEvents(eventName, id, name, creativeName, position)
{
    if (!id) {
        return;
    }

    if (eventName === 'view_promotion' || eventName === 'send_view_promotions') {
        if (eventName === 'view_promotion') {
            promotionsViews.push(
                {
                    promotion_id: id,
                    promotion_name: name,
                    creative_name: creativeName,
                    creative_slot: position
                }
            );
        }
        if (promotionsViews.length === 3 || eventName === 'send_view_promotions') {
            dataLayer.push({ecommerce: null});
            dataLayer.push({
                event: eventName,
                ecommerce: {
                    items: promotionsViews
                }
            });
            promotionsViews = [];
        }
    } else {
        if (eventName === 'select_promotion') {
            if (typeof (Storage) !== 'undefined') {
                sessionStorage.setItem('selectedPromotionId', id);
                sessionStorage.setItem('selectedPromotionName', name);
            }
            dataLayer.push({ecommerce: null});
            dataLayer.push({
                event: eventName,
                ecommerce: {
                    items: {
                        promotion_id: id,
                        promotion_name: name,
                        creative_name: creativeName,
                        creative_slot: position
                    }
                }
            });
        } else {
            dataLayer.push({ecommerce: null});
            dataLayer.push({
                event: eventName,
                ecommerce: {
                    items: [
                        {
                            promotion_id: id,
                            promotion_name: name,
                            creative_name: creativeName,
                            creative_slot: position
                        }
                    ]
                }
            });
        }
    }
}