import {clickEvent, fireClickEvent} from "../../webpack/modules/events";
import {
    answer,
    closeProductFinder, getCurrentProductFinderPage,
    goBackProductFinder,
    openProductFinder,
    restartProductFinder,
    showLabel
} from "../../webpack/modules/product-finder";
import {trackEvent, ga4StickerFinderTrackEvent} from "../../webpack/modules/analytics";

showLabel();

clickEvent('.product-finder-open', true, function(element) {
    trackEvent('Sticker Finder', element.dataset.action, 'Open');
    ga4StickerFinderTrackEvent('Sticker Finder', element.dataset.action, 'Open');
    openProductFinder();
});

clickEvent('#pf-close', false, function() {
    trackEvent('Sticker Finder', getCurrentProductFinderPage(), 'Close');
    ga4StickerFinderTrackEvent('Sticker Finder', getCurrentProductFinderPage(), 'Close');
    closeProductFinder();
});
clickEvent('.pf-overlay', true, function() {{
    trackEvent('Sticker Finder', getCurrentProductFinderPage(), 'Close');
    ga4StickerFinderTrackEvent('Sticker Finder', getCurrentProductFinderPage(), 'Close');
    closeProductFinder();
}});
clickEvent('#pf-back', false, function() {
    trackEvent('Sticker Finder', getCurrentProductFinderPage(), 'Back');
    ga4StickerFinderTrackEvent('Sticker Finder', getCurrentProductFinderPage(), 'Back');
    goBackProductFinder();
});
clickEvent('#pf-restart', false, function() {
    let element = document.querySelector('#pf-restart');
    if (window.innerWidth <= 768 && !element.classList.contains('show-tip')) {
        element.classList.add('show-tip');
    } else {
        element.classList.remove('show-tip');
        trackEvent('Sticker Finder', getCurrentProductFinderPage(), 'Restart');
        ga4StickerFinderTrackEvent('Sticker Finder', getCurrentProductFinderPage(), 'Restart');
        restartProductFinder();
    }
});
clickEvent('#product-finder *:not(#pf-restart)', true, function(element, event) {
    if (event.target && event.target.id !== 'pf-restart') {
        document.querySelector('#pf-restart').classList.remove('show-tip');
    }
});

clickEvent('#pfs-btn', true, function() {
    trackEvent('Sticker Finder', getCurrentProductFinderPage(), 'Start Now');
    ga4StickerFinderTrackEvent('Sticker Finder', getCurrentProductFinderPage(), 'Start Now');

    answer();
});

clickEvent('#pfpa-next', true, function() {
    let currentProduct = document.querySelector('.pfp-product.current');
    let nextProductPosition = parseInt(currentProduct.dataset.position) + 1;
    let nextProduct = document.querySelector(`.pfp-product.next[data-position="${nextProductPosition}"]`);
    currentProduct.classList.remove('current');
    currentProduct.classList.add('prev');
    nextProduct.classList.remove('next');
    nextProduct.classList.add('current');

    document.querySelector('#pfpa-prev').classList.remove('hidden');
    if (!document.querySelector(`.pfp-product.next`)) {
        document.querySelector('#pfpa-next').classList.add('hidden');
    }
});

clickEvent('#pfpa-prev', true, function() {
    let currentProduct = document.querySelector('.pfp-product.current');
    let prevProductPosition = parseInt(currentProduct.dataset.position) - 1;
    let prevProduct = document.querySelector(`.pfp-product.prev[data-position="${prevProductPosition}"]`);
    currentProduct.classList.remove('current');
    currentProduct.classList.add('next');
    prevProduct.classList.remove('prev');
    prevProduct.classList.add('current');

    document.querySelector('#pfpa-next').classList.remove('hidden');
    if (!document.querySelector(`.pfp-product.prev`)) {
        document.querySelector('#pfpa-prev').classList.add('hidden');
    }
});

clickEvent('.pfa-answer', true, function(element) {
    let id = element.dataset.id;
    let name = element.dataset.name;
    let answers = document.querySelector('#pf-answers').value;
    let answersNames = document.querySelector('#pf-answers-names').value;
    answers += ',' + id;
    answers = answers.replace(/^,|,$/g, "");
    answersNames += ',' + name;
    answersNames = answersNames.replace(/^,|,$/g, "");
    document.querySelector('#pf-answers').value = answers;
    document.querySelector('#pf-answers-names').value = answersNames;
    trackEvent('Sticker Finder', getCurrentProductFinderPage(), answersNames);
    ga4StickerFinderTrackEvent('Sticker Finder', getCurrentProductFinderPage(), answersNames);
    answer();
});

clickEvent('.pfp-image', true, function(element, event) {
    event.stopPropagation();
    let linkElement = element.closest('.pfp-product').querySelector('.pfpp-btn a')
    let link = linkElement.href;
    let productReference = linkElement.dataset.reference;
    let productName = linkElement.dataset.name;
    let productTitle = element.closest('.pfp-product').querySelector('.pfpp-title').innerHTML;
    trackEvent('Sticker Finder', 'Click Result', productTitle);
    ga4StickerFinderTrackEvent('Sticker Finder', 'Click Result', {name: productName, reference: productReference});
    window.location.href = link;
});

clickEvent('.pfp-product a', true, function(element) {
    let productReference = element.dataset.reference;
    let productName = element.dataset.name;
    let productTitle = element.closest('.pfp-product').querySelector('.pfpp-title').innerHTML;
    trackEvent('Sticker Finder', 'Click Result', productTitle);
    ga4StickerFinderTrackEvent('Sticker Finder', 'Click Result', {name: productName, reference: productReference});
});

