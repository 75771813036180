require('./files/home');
require('./../website/scripts/ProductFinder/main');
require('./files/bannerHome');

require('../../css/web/home.css');
require('../../css/web/offers.css');
require('../../css/web/reviews.css');
require('../../css/web/seasonal-promotion-banner.css');
require('../../css/web/content-schedule-banners.css');
require('../../css/web/bannerImage.css');
require('../../sass/web/_home_top_sales.scss');
require('../../sass/web/product_finder.scss');